import React from 'react'
import playIcon from "../assets/img/Play.png"
import { Link } from 'gatsby'
import Modal from 'react-modal'
import Modalresults from './modal'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

// Modal.setAppElement('#main')

const Results = ({ brand, image, reference, details, code, usine }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    return (
        <>
            <div id="main">
                <div className="item">
                    <div className="image">
                        <img width="250" src={image} alt="" />
                        <Link to="#" onClick={openModal}>
                            Voir tous les résultats
                        </Link>
                    </div>
                    <div className="content">
                        <div className="reference"> <h3>{usine}</h3> </div>
                        <div className="ref-usine">
                            <h3><span className='pre-title'>DESIGNATION USINE :</span> {usine}</h3>
                        </div>
                        <div className="br-list">
                            <div className="ref-setem">
                                <h3><span className="pre-title">CODE SETEM :</span> <strong>{reference}</strong></h3>
                            </div>
                            <div className="code">
                                <h3><span className="pre-title">CODE USINE :</span> <strong>{code}</strong></h3>
                            </div>
                            <div className="brand">
                                <h3><span className="pre-title">Marque</span> <strong>{brand}</strong></h3>
                            </div>
                        </div>
                        <div className="link-more">
                            <a href="#"><span><img src={playIcon} alt="" /></span> En savoir plus</a>
                        </div>
                    </div>

                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <div className="modal-container">
                            <div className="modal-top">
                                <div className="content-top">
                                    <div className="modal-title">{usine}</div>
                                </div>
                                <div className="content">
                                    <div className="ref-usine">
                                        <h3><span className='pre-title'>DESIGNATION USINE :</span> {usine}</h3>
                                    </div>
                                    <div className="br-list">
                                        <div className="ref-setem">
                                            <h3><span className="pre-title">CODE SETEM :</span> <strong>{reference}</strong></h3>
                                        </div>
                                        <div className="code">
                                            <h3><span className="pre-title">CODE USINE :</span> <strong>{code}</strong></h3>
                                        </div>
                                        <div className="brand">
                                            <h3><span className="pre-title">Marque</span> <strong>{brand}</strong></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-core">
                                <div className="core-content">
                                    <div className="image">
                                        <img src={image} alt="" />
                                    </div>
                                    <div className="details">
                                        {details}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Modalresults  />

                    </Modal>
                </div>
            </div>
        </>
    )
}

export default Results